import { environment } from '../../environments/environment';
import { StorageService } from './services/storage.service';
import { StorageConstants } from './models/storage-constants';

export function jwtOptionsFactory(storageService: StorageService): Record<string, unknown> {
  return {
    tokenGetter: () => storageService.get(StorageConstants.AuthToken),
    allowedDomains: environment.whitelist,
    disallowedRoutes: environment.blacklist,
  };
}
