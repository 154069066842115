import {Component, OnDestroy} from '@angular/core';
import {Platform} from '@ionic/angular';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';
import {Network} from '@awesome-cordova-plugins/network/ngx';
import {ToastService} from './common/services/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {SplashService} from './common/services/splash.service';
import {Router} from '@angular/router';
import {register} from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
  private subscription: Subscription;

  constructor(
    private router: Router,
    private platform: Platform,
    private statusBar: StatusBar,
    private screenOrientation: ScreenOrientation,
    private network: Network,
    private toastService: ToastService,
    private translate: TranslateService,
    private splashService: SplashService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      if (this.platform.is('android')) {
        this.statusBar.styleLightContent();
      } else {
        this.statusBar.styleDefault();
      }
      this.translate.setDefaultLang('de');
      this.translate.use('de').subscribe(
          () => { console.log('Initialized translations') }
      );
      await this.splashService.present();
      if (!this.platform.is('desktop')) {
        await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
      this.subscription = this.network.onDisconnect().subscribe(async () => {
        await this.toastService.presentToast(this.translate.instant('error.network'));
        await this.router.navigate(['tabs/code']);
      });
    });
  }

  ngOnDestroy() {
    return this.subscription && this.subscription.unsubscribe();
  }
}
