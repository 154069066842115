import {Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FieldsService} from '../../../beds/services/fields.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-unregister-code',
  templateUrl: './unregister-code.component.html',
  styleUrls: ['./unregister-code.component.scss'],
})
export class UnregisterCodeComponent {
  constructor(private modalController: ModalController,
              private readonly fieldsService: FieldsService,
              private router: Router) {
  }

  delete(): void {
    this.fieldsService.unregisterCode()
      .subscribe(async () => {
        this.modalController.dismiss();
        await this.fieldsService.removeCachedNumberOfFields();
        this.router.navigate(['tabs/code']);
      });
  }

  dismiss(): void {
    this.modalController.dismiss();
  }
}
