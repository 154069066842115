import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {LoadingController} from '@ionic/angular';
import {environment} from '../../../environments/environment';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(private controller: LoadingController) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('assets') ||
        !this.controller ||
        req.url === `${environment.baseUrl}/beds` ||
        req.url === `${environment.baseUrl}/categorized-article-list`) {
      return next.handle(req);
    }
    return from(this.controller.create({mode: 'ios'}))
      .pipe(
        tap(loading => loading.present()),
        switchMap(loading =>
          next.handle(req).pipe(finalize(() => loading.dismiss()))
        )
      );
  }
}
