import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {StorageConstants} from '../models/storage-constants';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage;

  constructor(
    private storage: Storage,
  ) {}

  async init() {
    this._storage = await this.storage.create();
    await this.migrateFromCordovaStore();
  }

  private async migrateFromCordovaStore(): Promise<void> {
    try {
      const onboarding = localStorage.getItem(`_cap_${StorageConstants.Onboarding}`);
      if (onboarding) {
        await this._storage.set(StorageConstants.Onboarding, onboarding);
        localStorage.removeItem(`_cap_${StorageConstants.Onboarding}`);
      }

      const authToken = localStorage.getItem(`_cap_${StorageConstants.AuthToken}`);
      if (authToken) {
        await this._storage.set(StorageConstants.AuthToken, authToken);
        localStorage.removeItem(`_cap_${StorageConstants.AuthToken}`);
      }

      const numberOfFields = localStorage.getItem(`_cap_${StorageConstants.NumberOfFields}`);
      if (numberOfFields) {
        await this._storage.set(StorageConstants.NumberOfFields, numberOfFields);
        localStorage.removeItem(`_cap_${StorageConstants.NumberOfFields}`);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async store(storageKey: string, value: string): Promise<void> {
    const encryptedValue = btoa(escape(value));
    await this._storage.set(storageKey, encryptedValue);
  }

  async get(storageKey: string): Promise<string> {
    const storageValue = await this._storage?.get(storageKey);
    if (!storageValue) {
      return null;
    }

    return unescape(atob(storageValue));
  }

  async removeStorageItem(storageKey: string): Promise<void> {
    await this._storage.remove(storageKey);
  }

  async clear(): Promise<void> {
    await this._storage.clear();
  }
}