import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ICode} from '../models/code';
import {environment} from '../../../environments/environment';
import {ToastService} from '../../common/services/toast.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CodeService {
  public endpoint = `${environment.baseUrl}/locations/subscription`;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private translate: TranslateService,
    ) {
  }

  checkError(fieldName: string, errorCode: string): Promise<void> {
    switch (fieldName) {
      case 'code': {
        switch (errorCode) {
          case 'not_found':
            return this.toastService.presentToast(
              this.translate.instant('code.error.backend.code')
            );
        }
        break;
      }
    }

    return this.toastService.presentToast(
      this.translate.instant('error.general')
    );
  }

  sendCode(postData: ICode): Observable<void> {
    return this.http.post<void>(this.endpoint, postData);
  }
}
