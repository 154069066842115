import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage-angular';
import {StatusBar} from '@awesome-cordova-plugins/status-bar/ngx';
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {ErrorHandlingInterceptor} from './common/services/error-handling.interceptor';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {StorageService} from './common/services/storage.service';
import {NgxZendeskWebwidgetModule, NgxZendeskWebwidgetConfig} from '@nitsanzo/ngx-zendesk-webwidget';
import {environment} from '../environments/environment';
import {Network} from '@awesome-cordova-plugins/network/ngx';
import {LoadingInterceptor} from './common/services/loading.interceptor';
import {SplashComponent} from './common/components/splash/splash.component';
import {UnregisterCodeComponent} from './common/components/unregister-code/unregister-code.component';
import {jwtOptionsFactory} from './common/helpers';
import {SentryModule} from './sentry/sentry.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  lazyLoad = true;
  accountUrl = environment.zendeskAccountUrl;

  callback(zE) {
    zE('webWidget', 'updateSettings', {
      webWidget: {
        navigation: {
          popoutButton: {
            enabled: false
          }
        },
        contactForm: {
          title: {
            '*': 'AckerSupport'
          }
        },
        chat: {
          title: {
            '*': 'AckerSupport',
          }
        }
      }
    });
    zE('webWidget', 'hide');
    zE('webWidget', 'setLocale', 'de');
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SplashComponent,
    UnregisterCodeComponent
  ],
  imports: [
    SentryModule,
    BrowserModule,
    IonicModule.forRoot({swipeBackEnabled: false}),
    IonicStorageModule.forRoot({
      name: 'ackerpause',
      storeName: 'app',
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [StorageService]
      }
    }),
    NgCircleProgressModule.forRoot(),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig)
  ],
  providers: [
    StatusBar,
    ScreenOrientation,
    Network,
    {
      provide: APP_INITIALIZER,
      useFactory: (storageService: StorageService) => () => storageService.init(),
      deps: [StorageService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
