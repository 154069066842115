import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  browserTracingIntegration,
  createErrorHandler,
  init,
  replayIntegration,
  TraceService
} from '@sentry/angular-ivy';
import { environment } from '../../environments/environment';

init({
  dsn: environment.sentryDsn,
  environment: environment.sentryEnv ?? 'dev',
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
  ]
});

/**
 * https://docs.sentry.io/platforms/javascript/guides/angular/
 * https://docs.sentry.io/platforms/javascript/guides/cordova/ionic/
 */
@NgModule({
  imports: [
    BrowserModule,
  ],
  providers:[
    {
      provide: ErrorHandler,
      //instantiate default error handler if sentryDsn is empty-ish, in order to have a console.log
      useValue: environment.sentryDsn ? createErrorHandler() : new ErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ]
})
export class SentryModule {}
