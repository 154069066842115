import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { StorageConstants } from '../../common/models/storage-constants';
import { StorageService } from '../../common/services/storage.service';
import { INumberOfConnectedFields } from '../models/field';

@Injectable()
export class FieldsService {
  constructor(private readonly http: HttpClient,
              private readonly storageService: StorageService,
              private router: Router,
  ) {}

  public checkFields(): void {
    this.storageService.get(StorageConstants.NumberOfFields).then((data) => {
      const now = Date.now();
      if (data) {
        const numberOfFields = JSON.parse(data);
        
        if (now < numberOfFields.exp) {
          return this.checkNumberOfFields(numberOfFields.count);
        }
      }

      this.getNumberOfConnectedFields()
        .subscribe(async (details: INumberOfConnectedFields) => {
          await this.storageService.store(
            StorageConstants.NumberOfFields,
            JSON.stringify({
              count: details.fieldCount,
              exp: now + 86400000, //24 hours later
            })
          );
          this.checkNumberOfFields(details.fieldCount);
        });
    });
  }

  public checkNumberOfFields(numberOfFields: number): void {
    if (numberOfFields === 0) {
      this.router.navigate(['tabs/code']);
    }
  }

  public getNumberOfConnectedFields(): Observable<INumberOfConnectedFields> {
    return this.http
      .get<INumberOfConnectedFields>(`${environment.baseUrl}/fields/count`)
      .pipe(catchError(() => of({} as INumberOfConnectedFields)));
  }

  public async removeCachedNumberOfFields(): Promise<void> {
    return this.storageService.removeStorageItem(
      StorageConstants.NumberOfFields
    );
  }

  public unregisterCode(): Observable<void> {
    return this.http
      .delete<void>(`${environment.baseUrl}/user-locations`);
  }
}
