import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CodeService } from '../../code/services/code.service';
import { ToastService } from './toast.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  constructor(private toastService: ToastService,
              private translate: TranslateService,
              private codeService: CodeService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          from(this.checkValidationError(error));

          return throwError(() => error.error.error);
        }));
  }

  async checkValidationError(error: HttpErrorResponse): Promise<void> {
    const errorDetails = error && error.error && error.error.error;
    let fieldName: string = errorDetails?.details[0]?.path[0];
    let errorCode: string = errorDetails?.details[0]?.type;

    if (error.url === this.codeService.endpoint && fieldName && errorCode) {
      return this.codeService.checkError(fieldName, errorCode);
    } 
    await this.toastService.presentToast(this.translate.instant('error.general'));
  }
}
