<ion-grid>
  <ion-row>

      <ion-img class="logo" src="../../../../assets/images/logo.svg"></ion-img>

      <ion-label>{{('splashscreen.message' | translate)}}</ion-label>

      <ion-img class="footer-image" src="../../../../assets/images/splashscreen-footer.png"></ion-img>

  </ion-row>
</ion-grid>