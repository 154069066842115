<ion-header class="logo-wrapper" mode="ios">
  <img class="logo" src="../../../../assets/images/logo.svg">
</ion-header>

<ion-content class="content" mode="ios">
  <ion-row>
    <ion-col offset="2" size="8" class="info">
      <ion-text>{{('unregister-code.info' | translate)}}</ion-text>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col offset="2" size="8">
      <ion-button mode="md" color="danger" size="block" class="btn" (click)="delete()">
        <ion-label>{{('unregister-code.delete-btn' | translate)}}</ion-label>
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col offset="2" size="8">
      <ion-button mode="md" size="block" class="btn" (click)="dismiss()">
        <ion-label>{{('unregister-code.cancel-btn' | translate)}}</ion-label>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>