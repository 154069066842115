export const environment = {
  production: true,
  baseUrl: 'https://api-stage.ackerpause.de/v2',
  loginUrl: 'https://anbauplaner-stage.acker.co/api/v1/login/ackerpauseapp',
  whitelist: ['api-stage.ackerpause.de'],
  blacklist: ['api-stage.ackerpause.de/v1/login'],
  zendeskAccountUrl: 'ackerpause.zendesk.com',

  // sentry
  sentryEnv: 'stage',
  sentryDsn: 'https://591b28a63260797019592c58aab71697@o336081.ingest.sentry.io/4505680665051136',
};
