import {Injectable, OnDestroy} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {SplashComponent} from '../components/splash/splash.component';
import {timer, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplashService implements OnDestroy {
  private subscription: Subscription;

  constructor(public controller: ModalController) {}

  async present(): Promise<void> {
    const modal = await this.controller.create({
      component: SplashComponent,
      cssClass: 'splash-fullscreen'
    });
    await modal.present();
    // @ts-ignore
    navigator.splashscreen?.hide();
    this.subscription = timer(4000).subscribe(() => this.controller.dismiss());
  }

  ngOnDestroy(): void {
    return this.subscription && this.subscription.unsubscribe();
  }
}
